<template>
  <div class="home">

    <breadcrumb :items="breadcrumb" />

    <v-row>
      <v-col>

        <v-card flat class="card_action">
          <!-- <v-card-text> -->
            <v-row align-items="center">
              <v-col class="flex_end">
                <v-btn
                  color="error"
                  depressed
                  tile
                  @click="dialog = true"
                  v-if="$store.state.user_permissions.invoice_delete"
                >
                  Usuń
                </v-btn>
                <v-btn
                  color="primary"
                  depressed
                  tile
                  @click="editInvoice()"
                >
                  Zapisz zmiany
                </v-btn>
              </v-col>
            </v-row>

          <!-- </v-card-text> -->
        </v-card>
        
        
        <v-card>
          <v-card-title>Szczegóły faktury</v-card-title>
          <v-card-text>

            <v-form
              ref="form" 
              v-model="valid"
              lazy-validation
            >
              <v-text-field
                v-model="user_data.number_invoice"
                label="Numer faktury"
                color="primary"
                :rules="$store.state.rules.not_null"
              ></v-text-field>
              <v-text-field
                v-model="user_data.price"
                label="Kwota (netto)"
                color="primary"
                :rules="$store.state.rules.not_null"
              ></v-text-field>
            </v-form>

          </v-card-text>
        </v-card>
          

        <v-card class="card_section">
          <v-card-title>
            Daty
          </v-card-title>

          <v-card-text>
            
            <v-menu
              v-model="date_picker"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="user_data.date_create"
                  label="Kiedy wystawiono fakturę"
                  prepend-icon="mdi-calendar"
                  color="primary"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="user_data.date_create"
                @input="date_picker = false; securityCheck()"
                locale="pl-pl"
                color="primary"
              ></v-date-picker>
            </v-menu>

            <v-menu
              v-model="date_picker2"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="user_data.date_invoice"
                  label="Do kiedy płatność"
                  prepend-icon="mdi-calendar"
                  color="primary"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="user_data.date_invoice"
                :min="user_data.date_create"
                @input="date_picker2 = false"
                locale="pl-pl"
                color="primary"
              ></v-date-picker>
            </v-menu>

          </v-card-text>
        </v-card>

      </v-col>
    </v-row>

    <confirm_dialog
      v-if="dialog"
      text="Czy na pewno chcesz usuąć fakturę?"
      @close="dialog = false;"
      @confirm="deleteInvoice()"
    />
    
  </div>
</template>

<script>
export default {
  data: () => ({
    valid: false,
    id: 0,
    dialog: false,
    breadcrumb: [
      {
        text: 'Pulpit',
        disabled: false,
        to: '/panel',
      },
      {
        text: 'Lista faktur',
        disabled: false,
        to: '/faktury',
      },
      {
        text: 'Faktura',
        disabled: false,
        to: '/faktury/',
      },
      {
        text: 'Edycja faktury',
        disabled: true,
        to: 'edytuj',
      },
    ],

    user_data: {
      price: '123',
      status: false,
      date_create: new Date().toISOString().substr(0, 10),
      date_invoice: new Date().toISOString().substr(0, 10),
      number_invoice: '68/2/2021'
    },

    today: new Date().toISOString().substr(0, 10),

    date_picker: false,
    date_picker2: false
  }),
  methods: {
    // Sprawdzanie poprawności dat
    securityCheck(){
      if(this.user_data.date_create > this.user_data.date_invoice) this.user_data.date_invoice = this.user_data.date_create;
    },
    deleteInvoice(){
      this.$store.commit('loader');
      this.$axios({url: this.$store.state.api +'/invoice/'+this.id, data: {}, method: 'DELETE' })
      .then(resp => {
        this.$router.push('/faktury');
        this.$store.commit("snackbar", {
          text: "Usunięto fakturę",
          color: "primary",
          btn_color: "white"
        });
      })
      .catch(err => {
        this.$store.commit('snackbar', {text: err.response.data.response, color: 'red', btn_color: 'black'})
      })
      .then (() => {
        this.$store.commit('loader_off');
      })
    },
    editInvoice(){
      if(this.$refs.form.validate()) {
        this.$store.commit('loader');

        let formData = new FormData();
        formData.append("number_invoice", this.user_data.number_invoice);
        formData.append("date_create", this.user_data.date_create);
        formData.append("date_invoice", this.user_data.date_invoice);
        formData.append("price", this.user_data.price);

        this.$axios({url: this.$store.state.api +'/invoice/'+this.id+'?_method=PUT', data: formData, method: 'POST', headers: {'Content-Type': 'multipart/form-data'} })
          .then(resp => {
            // console.log(resp.data);

            this.$router.push('/faktura/zobacz/'+this.id);
            this.$store.commit("snackbar", {
              text: "Zaktualizowano fakturę",
              color: "primary",
              btn_color: "white"
            });
          })
          .catch(err => {
            this.$store.commit('snackbar', {text: err.response.data.response, color: 'red', btn_color: 'black'})
          })
          .then (() => {
            this.$store.commit('loader_off');
          })
      }
      else this.$store.commit('wrong_form_snackbar');
    },
  },
  mounted(){
    
    this.id = this.$route.params.id;
    console.log(this.id);

    // Edytowanie linku
    this.breadcrumb[2].to = '/faktura/zobacz/'+this.id;

    this.$store.commit('loader');
    this.$axios({url: this.$store.state.api +'/invoice/'+this.id, data: {}, method: 'GET' })
      .then(resp => {
        // this.list = resp.data;
        console.log(resp.data);
        this.user_data = resp.data;
        this.user_data.date_create = this.global_flipDate(this.user_data.date_create);
        this.user_data.date_invoice = this.global_flipDate(this.user_data.date_invoice);
      })
      .catch(err => {
        this.$store.commit('snackbar', {text: err.response.data.response, color: 'red', btn_color: 'black'})
      })
      .then (() => {
        this.$store.commit('loader_off');
      })
  }
};
</script>

<style lang="scss" scoped>
.card_section {
  margin-top: 25px;
}
.checkbox {
  margin-top: 0;
}
</style>